import AddressInputService from '../services/addressInput.service'

export const addressInput = {
    namespaced: true,
    actions: {
        getAddress(store, cords) {
            return AddressInputService.getAddress(cords)
                .then(res => {
                    if (res.success) {
                        return Promise.resolve(res)
                    }
                    return Promise.reject({ success: false, msg: 'failure' })
                })
                .catch(err => console.log(err))
        },

        getAddressHints(store, data) {
            const { region, street } = data
            return AddressInputService.getAddressHints(region, street)
                .then(res => {
                    if (!res.success) return { success: false }
                    return { success: true, res: res.res }
                })
                .catch(err => console.log(err))
        },

        getCoordinates(store, data) {
            console.log('getCoordinates', data)
            const { region, street } = data
            return AddressInputService.getCoordinates(street, region)
                .then(res => {
                    return Promise.resolve(res)
                })
                .catch(err => console.log(err))
        },
    },
}
