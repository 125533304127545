let config = require('./config.global')

config.env = 'development'
config.hostname = 'localhost'
config.mongo.db = 'local_db'

config.api.delivery = 'http://localhost:5501/'
// config.api.delivery = 'https://api.darall.pro/v1/'
// config.api.delivery = 'https://api.darall.pro/v2/'

module.exports = config;
