import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import vueConfig from '../vue.config'

import Login from '@/components/auth/Login'
import AppLayout from '@/components/AppLayout'

const Analytics = () => import('@/components/analytics/Analytics')
const Geofence = () => import('@/components/geofence/Geofence')
const Logistic = () => import('@/components/logistic/Logistic')
const Points = () => import('@/components/points/Points')
const Couriers = () => import('@/components/couriers/Couriers')
const Orders = () => import('@/components/orders/Orders')
const ServiceOrders = () => import('@/components/service_orders/Services')
const Services = () => import('@/components/services/Services')
const Workers = () => import('@/components/workers/Workers')
const Guests = () => import('@/components/guests/Guests')
const Settings = () => import('@/components/settings/Settings')
const Reviews = () => import('@/components/reviews/Reviews')
const Subscribe = () => import('@/components/subscribe/Subscribe')
const Statistics = () => import('@/components/statistics/Statistics')
// const aboutPoint = () => import('@/components/aboutPoint/AboutPoint')
const AnalyticsGuests = () => import('@/components/analytics/guests/AnalyticsGuests')
const Menus = () => import('@/components/menus/Menus')

Vue.use(Router)

let router = new Router({
    mode: 'history',
    hash: false,
    routes: [
        {
            name: 'app',
            path: vueConfig.publicPath,
            component: AppLayout,
            meta: {
                requiresAuth: true,
            },
            redirect: { name: 'analytics' },
            children: [
                {
                    name: 'analytics',
                    path: vueConfig.publicPath + 'analytics',
                    component: Analytics,
                    default: true,
                },
                // {
                //     name: 'analytics_guests',
                //     path: vueConfig.publicPath + 'analytics_guests',
                //     component: AnalyticsGuests,
                // },
                {
                    name: 'geofence',
                    path: vueConfig.publicPath + 'geofence',
                    component: Geofence,
                },
                {
                    name: 'couriers',
                    path: vueConfig.publicPath + 'couriers',
                    component: Couriers,
                },
                {
                    name: 'points',
                    path: vueConfig.publicPath + 'points',
                    component: Points,
                },
                {
                    name: 'orders',
                    path: vueConfig.publicPath + 'orders',
                    component: Orders,
                },
                {
                    name: 'service_orders',
                    path: vueConfig.publicPath + 'service_orders',
                    component: ServiceOrders,
                },
                {
                    name: 'services',
                    path: vueConfig.publicPath + 'services',
                    component: Services,
                },
                {
                    name: 'workers',
                    path: vueConfig.publicPath + 'workers',
                    component: Workers,
                },
                {
                    name: 'guests',
                    path: vueConfig.publicPath + 'guests',
                    component: Guests,
                },
                {
                    name: 'guests_phone',
                    path: vueConfig.publicPath + 'guests/:phone',
                    component: Guests,
                    // props: true
                },
                {
                    name: 'logistic',
                    path: vueConfig.publicPath + 'logistic',
                    component: Logistic,
                },
                {
                    name: 'settings',
                    path: vueConfig.publicPath + 'settings',
                    component: Settings,
                },
                {
                    name: 'reviews',
                    path: vueConfig.publicPath + 'reviews',
                    component: Reviews,
                },
                {
                    name: 'subscribe',
                    path: vueConfig.publicPath + 'subscribe',
                    component: Subscribe,
                },
                {
                    name: 'statistics',
                    path: vueConfig.publicPath + 'statistics',
                    component: Statistics,
                },
                // {
                //     name: 'aboutPoint',
                //     path: vueConfig.publicPath + 'aboutPoint',
                //     component: aboutPoint,
                // },
                {
                    name: 'menus',
                    path: vueConfig.publicPath + 'menus',
                    component: Menus,
                },
            ],
        },
        {
            name: 'login',
            path: vueConfig.publicPath + 'login',
            component: Login,
        },
        {
            path: '**',
            // name: 'http404',
            // component: http404,
            // meta: {
            //     requiresAuth: true,
            // },
            redirect: { name: 'analytics' },
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next(vueConfig.publicPath + 'login')
    } else {
        next()
    }
})

export default router
