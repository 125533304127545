import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import config from './config'

import VueYandexMetrika from 'vue-yandex-metrika'

import VueDragAndDropList from 'vue-drag-and-drop-list'
Vue.use(VueDragAndDropList)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { languages } from './i18n'
import { defaultLocale } from './i18n'
const messages = Object.assign(languages)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.config.productionTip = false

Vue.prototype.$store = store

localStorage.removeItem('user')
localStorage.removeItem('token')
localStorage.removeItem('settings')

Vue.prototype.$http = axios
const token = store.state.token
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.prototype.$http.defaults.baseURL = config.api.delivery

Vue.prototype.$http.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.data && error.response.data.success === false && error.response.data.msg === 'Token expired') {
        if (store.getters.isLoggedIn) {
            Vue.prototype.$notify({
                text: 'Время сессии вышло. Пожалуйста, авторизуйтесь снова',
                type: 'error',
                duration: 15000
            })
            store.dispatch('logout')
                .then(() => {
                    router.push({ name: 'login' })
                })
        }
    }

    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
})

if (store.state.user) {
    if (!store.state.settings) {
        store.state.settings = store.state.user.settings
    }

    if (!store.state.settings.tz) {
        store.state.settings.tz = config.tz
    }
    Vue.prototype.$http.defaults.headers.common['tz'] = store.state.settings.tz

    if (!store.state.settings.tz_string) {
        store.state.settings.tz_string = config.tz_string
    }
    Vue.prototype.$http.defaults.headers.common['tz_string'] = store.state.settings.tz_string


    if (!store.state.settings.city) {
        store.state.settings.city = config.city
    }

    if (!store.state.operator_tid) {
        if (store.state.user.role == config.user.role.operator) {
            store.state.operator_tid = store.state.user.tid
        } else {
            store.state.operator_tid = store.state.user.operator_tid
        }
    }
}

import Notifications from 'vue-notification'
Vue.use(Notifications)

var i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'ru',
    messages,
})

Vue.use(VueYandexMetrika, {
    id: 70786153,
    router,
    env: process.env.NODE_ENV,
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
})

Vue.use(Vuex)

Vue.prototype.$vm = new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')
