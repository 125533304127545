<template>
    <div>
        <v-btn class="ml-4" text @click="onClickSettingsDownload">
            <v-icon dense class="mr-1">mdi-download</v-icon>
            Сохранить
        </v-btn>
        <v-btn class="ml-4" text @click="$refs.inputUpload.click()">
            <v-icon dense class="mr-1">mdi-upload</v-icon>
            Открыть
        </v-btn>
        <input v-show="false" ref="inputUpload" type="file" accept=".json" @change="onClickSettingsUpload" />
    </div>
</template>

<script>
    import moment from 'moment-timezone'
    moment.locale('ru')

    export default {
        components: {},
        data() {
            return {}
        },
        methods: {
            downloadObjectAsJson(exportObj, exportName) {
                var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
                var downloadAnchorNode = document.createElement('a')
                downloadAnchorNode.setAttribute('href', dataStr)
                downloadAnchorNode.setAttribute('download', `${exportName}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.json`)
                document.body.appendChild(downloadAnchorNode) // required for firefox
                downloadAnchorNode.click()
                downloadAnchorNode.remove()
            },
            onClickSettingsDownload() {
                const settings = this.$store.state.settings
                this.downloadObjectAsJson(settings, 'DarallProSettings')
                this.$vm.$emit('settings_download', settings)
            },
            onClickSettingsUpload(e) {
                const reader = new FileReader()
                reader.onload = ev => {
                    const settings = JSON.parse(ev.target.result)
                    this.$store.commit('settings', settings)
                    // console.log('onload settings.prices', JSON.stringify(settings.prices))
                    this.$vm.$emit('settings_upload', settings)
                }
                reader.readAsText(e.target.files[0])
            },
        },
    }
</script>
