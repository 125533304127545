import en from './en.json'
import ru from './ru.json'

// export const defaultLocale = 'en'
export const defaultLocale = 'ru'

export const languages = {
    en: en,
    ru: ru,
}
