<template>
    <v-app id="app">
        <notifications />
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: 'App',
        computed: {
        },
        methods: {
            logout: function() {
                console.log('app logout')
                this.$store.dispatch('logout').then(() => {
                    this.$router.push({ name: 'login' })
                })
            },
        },
        created: function() {
            console.log('App created')
        },
    }
</script>

<style lang="stylus">
    html
        // touch-action none !important
        overflow-y auto !important
        // height: 100vh !important
        // width: 100vw !important
        // touch-action: none !important
        // -webkit-overflow-scrolling: none !important
        // overflow: hidden !important
        // overscroll-behavior: none !important

    #app
        width 100%
        height 100%
        z-index 1
        display block
        position absolute

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
        -webkit-appearance none
        margin 0

    /* Firefox */
    input[type=number]
        -moz-appearance textfield

    .vue-notification {
        // padding: 10px;
        // margin: 0 5px 5px;
        top: 10px !important;
        right: 10px !important;
        font-size: 16px !important;
        // color: #ffffff;
        // background: #44A4FC;
        // border-left: 5px solid #187FE7;

        // &.warn {
        //     background: #ffb648;
        //     border-left-color: #f48a06;
        // }

        // &.error {
        //     background: #E54D42;
        //     border-left-color: #B82E24;
        // }

        // &.success {
        //     background: #68CD86;
        //     border-left-color: #42A85F;
        // }
    }

</style>
