<template>
    <div>
        <!-- <v-btn class="ml-4" text @click="onClickSettingsDownload">
            <v-icon dense class="mr-1">mdi-download</v-icon>
            Сохранить
        </v-btn> -->

        <v-row>
            <v-btn class="ml-6" text outlined small tile @click="onClickGeofenceUpload">
                <v-icon dense class="mr-1">mdi-upload</v-icon>
                Импорт Kml
            </v-btn>
            <input v-show="false" ref="inputUpload" type="file" accept=".kml" @change="onChangeGeofenceUpload" />
            <v-btn class="mx-2" text outlined small tile @click="onClickGeofenceDownload">
                <v-icon dense class="mr-1">mdi-download</v-icon>
                Экспорт Kml
            </v-btn>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialog" max-width="400">
                <v-card>
                    <v-card-title class="text-h5">
                        Импорт геозон
                    </v-card-title>

                    <v-card-text>
                        Выполнить очистку всех текущих геозон перед импортом новых?
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="grey darken-1" text @click="dialog = false">
                            Отменить
                        </v-btn>

                        <v-btn color="red darken-1" text @click="onChangeClear(true)">
                            Очистить
                        </v-btn>

                        <v-btn color="blue darken-1" text @click="onChangeClear(false)">
                            Оставить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    // import moment from 'moment-timezone'
    // moment.locale('ru')

    export default {
        components: {},
        data() {
            return {
                dialog: false,
                clearFlag: false,
            }
        },
        methods: {
            onClickGeofenceUpload(e) {
                this.dialog = true
            },
            onChangeClear(flag) {
                this.dialog = false
                this.clearFlag = flag
                this.$refs.inputUpload.click()
            },
            onChangeGeofenceUpload(e) {
                const reader = new FileReader()
                reader.onload = () => {
                    this.$vm.$emit('geofence_upload', {
                        geofences: reader.result,
                        clearFlag: this.clearFlag,
                    })
                }
                reader.readAsText(e.target.files[0])
            },
            onClickGeofenceDownload() {
                this.$vm.$emit('geofence_download')
            },
        },
    }
</script>
