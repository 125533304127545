import axios from 'axios'

const config_kladr_url = 'https://kladr.darall.ru/'

class AddressInputService {
    async getAddress(cords) {
        return await axios
            .get(`${config_kladr_url}get_address?lon=${cords.lng}&lat=${cords.lat}`)
            .then(res => {
                if (!res.data.success) {
                    return { success: false }
                }
                return res.data
            })
            .catch(err => console.log(err))
    }

    async getCoordinates(street, city) {
        return await axios
            .get(`${config_kladr_url}v2/get_coords?street=${street}${city ? '&city=' + city : ''}`)
            .then(res => {
                if (!res.data.success) {
                    return { success: false }
                }
                return res.data
            })
            .catch(err => console.log(err))
    }

    async getAddressHints(region, street) {
        return await axios
            .get(`${config_kladr_url}v2/get_hints?${region ? 'region=' + region : ''}&street=${street}`)
            .then(res => {
                if (!res.data.success) {
                    return { success: false }
                }
                return res.data
            })
            .catch(err => {
                console.log(err)
                return { success: false }
            })
    }
}

export default new AddressInputService()
